// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import "@cloudscape-design/global-styles/index.css";

import markdownRoutes from "../../markdownRoutes.json";

import React, { Dispatch, SetStateAction } from "react";

import {
	Box,
	Button,
	Container,
	Grid,
	Header,
	Link,
	SpaceBetween,
} from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";

import { Segment } from "../../enum";
import Chat from "../partial/chat";

interface Props {
	setSegment: Dispatch<SetStateAction<Segment>>;
}

type LinkItem = {
	title: string;
	link: string;
};

type Sections = {
	[key: string]: LinkItem[];
};

const formatWord = (str: string) => {
	return capitaliseEachWord(preferAmpersand(str));
};

const capitaliseEachWord = (str: string) => {
	return str
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

const preferAmpersand = (str: string) => {
	return str.replace(/ and /g, " & ");
};

export default function Council({ setSegment }: Props) {
	setSegment(Segment.COUNCIL);

	const segmentText =
		Segment.COUNCIL.charAt(0).toUpperCase() + Segment.COUNCIL.slice(1);
	document.title = `Home - ACME ${segmentText}`;

	const councilRoutes = markdownRoutes.filter((route) =>
		route.startsWith("/council/")
	);
	const sections: Sections = {};

	councilRoutes.forEach((route) => {
		const [, , section, ...rest] = route.split("/");
		if (!sections[section]) {
			sections[section] = [];
		}
		sections[section].push({
			title: rest.join(" ").replace(/-/g, " "),
			link: route,
		});
	});

	const generateGridDefinition = (count: number) => {
		return Array(count).fill({ colspan: { default: 12, xxs: 4 } });
	};

	return (
		<>
			<ContentLayout
				defaultPadding={true}
				maxContentWidth={1000}
				disableOverlap
				headerBackgroundStyle={(mode) =>
					`center center/cover url("/council.png")`
				}
				header={
					<Box padding={{ vertical: "xxxl" }}>
						<Grid gridDefinition={[{ colspan: { default: 12, s: 8 } }]}>
							<Container>
								<Box padding="s">
									<Box
										fontSize="display-l"
										fontWeight="bold"
										variant="h1"
										padding="n"
									>
										ACME Council
									</Box>
									<Box fontSize="display-l" fontWeight="light">
										The best place to find your council services and
										information.
									</Box>
									<Box
										variant="p"
										color="text-body-secondary"
										margin={{ top: "xs", bottom: "l" }}
									>
										We're dedicated to simplifying access and empowering you to
										engage with your neighborhood.
									</Box>
									<SpaceBetween direction="horizontal" size="xs">
										<Button variant="primary">Explore Services</Button>
										<Button>Get Involved</Button>
									</SpaceBetween>
								</Box>
							</Container>
						</Grid>
					</Box>
				}
			>
				<Box variant="h2" padding={{ top: "xxl" }}>
					Popular Services & Information
				</Box>
				<Box variant="h2" padding={{ top: "xxl" }}>
					<Grid gridDefinition={generateGridDefinition(Object.keys(sections).length)}>
						{Object.entries(sections).map(([section, items]) => (
							<Container
								fitHeight
								key={section}
								header={
									<Header variant="h2" counter={`(${items.length})`}>
										{formatWord(section.replace(/-/g, " "))}
									</Header>
								}
							>
								<ul>
									{items.map((item, index) => (
										<Link key={index} href={item.link}>
											<li>{formatWord(item.title)}</li>
										</Link>
									))}
								</ul>
							</Container>
						))}
					</Grid>
				</Box>
			</ContentLayout>

			<Chat
				embedUrl="https://zbewon5l.chat.qbusiness.us-east-1.on.aws/"
				embedWidth={600}
				embedHeight={650}
			/>
		</>
	);
}
