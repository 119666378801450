// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import "@cloudscape-design/global-styles/index.css";

import React, { Dispatch, SetStateAction } from "react";

import {
	Box,
	BreadcrumbGroup,
	Header,
} from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";

import { Segment } from "../../enum";

interface Props {
	setSegment: Dispatch<SetStateAction<Segment>>;
}

export default function Contact({ setSegment }: Props) {
	setSegment(Segment.COUNCIL);

	const segmentText = Segment.COUNCIL.charAt(0).toUpperCase() + Segment.COUNCIL.slice(1);
	document.title = `Contact - ACME ${segmentText}`;

	return (
		<>
			<ContentLayout
				defaultPadding={true}
				maxContentWidth={1000}
				disableOverlap
				headerVariant="divider"
				breadcrumbs={
					<BreadcrumbGroup
						items={[
							{ text: "Council", href: "/council/" },
							{ text: "Contact Us", href: "#" },
						]}
						ariaLabel="Breadcrumbs"
					/>
				}
				header={<Header variant="h1">Contact Us</Header>}
			>
				<Box padding={{ top: "xxl" }}>
					We're here to help. Find the right department to address your needs
					quickly and efficiently.
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					General Enquiries
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567000</li>
						<li><b>Email</b>: <a href="#">info@acme-council.gov.uk</a></li>
						<li><b>Hours</b>: Monday to Friday, 9am to 5pm</li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Customer Service Centre
				</Box>
				<Box>
					<ul>
						<li><b>Address</b>: 1 High Street, Town Centre</li>
						<li><b>Phone</b>: 01234 567001</li>
						<li><b>Email</b>: <a href="#">customerservice@acme-council.gov.uk</a></li>
						<li>
							<b>Hours</b>: Monday to Friday, 8:30am to 5:30pm; Saturday, 9am to 1pm
						</li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Planning and Development
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567002</li>
						<li><b>Email</b>: <a href="#">planning@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Housing Services
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567003</li>
						<li><b>Email</b>: <a href="#">housing@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Council Tax and Benefits
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567004</li>
						<li><b>Email</b>: <a href="#">counciltax@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Environmental Health
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567005</li>
						<li><b>Email</b>: <a href="#">environmental.health@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Waste and Recycling
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567006</li>
						<li><b>Email</b>: <a href="#">waste@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Highways and Transportation
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567007</li>
						<li><b>Email</b>: <a href="#">highways@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Education and Schools
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567008</li>
						<li><b>Email</b>: <a href="#">education@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Social Services
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567009</li>
						<li><b>Email</b>: <a href="#">socialservices@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Leisure and Culture
				</Box>
				<Box>
					<ul>
						<li><b>Phone</b>: 01234 567010</li>
						<li><b>Email</b>: <a href="#">leisure@acme-council.gov.uk</a></li>
					</ul>
				</Box>
				<Box variant="h3" padding={{ top: "xxl" }}>
					Out of <b>Hours</b> Emergency
				</Box>
				<Box>
					<ul>
						<li>
							<b>Phone</b>: 01234 567999 (for urgent issues outside normal office hours)
						</li>
					</ul>
				</Box>
			</ContentLayout>
		</>
	);
}
