// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import markdownRoutes from "./markdownRoutes.json";

import React, { Dispatch, SetStateAction } from 'react';
import { Route, Routes } from "react-router-dom";

import Council from "./page/council/council";
import Contact from "./page/council/contact";
import Home from "./page/home";
import MarkdownPage from "./page/MarkdownPage";
import { Segment } from "./enum";

interface Props {
	setSegment: Dispatch<SetStateAction<Segment>>;
}

export default function AppRoutes({setSegment}:Props) {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/council/" element={<Council setSegment={setSegment} />} />
			<Route path="/council/contact/" element={<Contact setSegment={setSegment} />} />
			{markdownRoutes.map((route) => (
				<Route
					key={route}
					path={route}
					element={<MarkdownPage route={route} setSegment={setSegment} />}
				/>
			))}
			{/* Fallback routes come last */}
			<Route path="/council/*" element={<Council setSegment={setSegment} />} />
			<Route path="*" element={<Home />} />
		</Routes>
	);
}
