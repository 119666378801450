import React from 'react';

import {
	Box,
	Container,
	Grid,
	Header,
	Link,
} from "@cloudscape-design/components";
import ContentLayout from "@cloudscape-design/components/content-layout";

export default function Home() {

	return (
		<ContentLayout
			defaultPadding={true}
			maxContentWidth={1000}
			disableOverlap
			header={
				<Header variant="h1" description="Select a service to explore">
					Welcome to ACME Services
				</Header>
			}
		>
			<Grid
				gridDefinition={[
					{ colspan: { default: 12, xxs: 6 } },
					{ colspan: { default: 12, xxs: 6 } },
				]}
			>
				<Container header={<Header variant="h2">Council Services</Header>}>
					<Box variant="p">
						Access information about local government services, community
						events, and more.
					</Box>
					<Link href="/council/">Go to Council Services</Link>
				</Container>
				{/* <Container header={<Header variant="h2">Housing Services</Header>}>
					<Box variant="p">
						Find information about housing assistance, rentals, and property
						management.
					</Box>
					<Link href="/housing/">Go to Housing Services (Coming Soon)</Link>
				</Container> */}
			</Grid>
		</ContentLayout>
	);
}
