// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from "react";

import {
	ColumnLayout,
	ContentLayout,
	Grid,
	Link,
} from "@cloudscape-design/components";

interface Props {
	segment: string;
}

export default function Footer({ segment }: Props) {
	const title = segment.charAt(0).toUpperCase() + segment.slice(1);
	const copyright = `© 2024 ACME ${title}, Inc. or its affiliates. All rights reserved.`;

	return (
		<footer data-selector="footer" role="navigation" aria-label="footer">
			<ContentLayout
				defaultPadding={true}
				maxContentWidth={1000}
				disableOverlap
			>
				<ColumnLayout columns={2} variant="text-grid">
					<div>
						<Link href={`/${segment}/`}>About Us</Link>&nbsp;|&nbsp;
						<Link href={`/${segment}/contact`}>Contact</Link>
					</div>
					<div>{copyright}</div>
				</ColumnLayout>
			</ContentLayout>
		</footer>
	);
}
