import React, { Dispatch, SetStateAction } from 'react';
import Markdown from "react-markdown";

import {
	Box,
	BreadcrumbGroup,
	Container,
	ContentLayout,
	Header,
} from "@cloudscape-design/components";

import remarkGfm from "remark-gfm";

import { Segment } from "../enum";

interface Props {
	setSegment: Dispatch<SetStateAction<Segment>>;
	route: string;
}

const MarkdownPage: React.FC<Props> = ({ setSegment, route }: Props) => {
	const [content, setContent] = React.useState<string | null>(null);
	const [error, setError] = React.useState<string | null>(null);

	setSegment(Segment.COUNCIL);

	React.useEffect(() => {
		const fetchMarkdown = async () => {
			try {
				const response = await fetch(`/content${route}.md`);
				if (!response.ok) {
					throw new Error("Failed to fetch markdown file");
				}
				const markdown = await response.text();
				setContent(markdown);
			} catch (err) {
				console.error("Error loading markdown file:", err);
				setError("Failed to load content. Please try again later.");
			}
		};
		fetchMarkdown();
	}, [route]);

	const title =
		route
			.split("/")
			.pop()
			?.replace(/-/g, " ")
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ") || "Page";

	const segmentText = Segment.COUNCIL.charAt(0).toUpperCase() + Segment.COUNCIL.slice(1);
	document.title = `${title} - ACME ${segmentText}`;

	if (error) {
		return (
			<Container header={<Header variant="h1">{title}</Header>}>
				<Box color="text-status-error">{error}</Box>
			</Container>
		);
	}

	if (!content) {
		return (
			<Container header={<Header variant="h1">{title}</Header>}>
				<Box>Loading...</Box>
			</Container>
		);
	}

	return (
		<ContentLayout
			defaultPadding={true}
			maxContentWidth={1000}
			disableOverlap
			headerVariant="divider"
			breadcrumbs={
				<BreadcrumbGroup
					items={[
						{ text: "Council", href: "/council/" },
						{ text: title, href: "#" },
					]}
					ariaLabel="Breadcrumbs"
				/>
			}
			header={<Header variant="h1">{title}</Header>}
		>
			<Box variant="p">
				<Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
			</Box>
		</ContentLayout>
	);
};

export default MarkdownPage;
